<template>
  <div class="container" 
    @touchstart="handleTouchStart" 
    @touchmove="handleTouchMove" 
    @touchend="handleTouchEnd">
    <header-page ref="header" v-show="!screenshoting" />

    <!-- 封面页 -->
    <div v-if="currentPage === 0" class="screen-cover">
      <footer>
        <div class="btn-open-annual-report" @click="handleToNext"></div>
        <div class="protocol-box" @click="agreeProtocol = !agreeProtocol">
          <div :class="['radio', agreeProtocol ? 'radio_sel' : 'radio_not']"></div>
          <span class="protocol-content">我已阅读并同意粗门查询使用个人数据生成<span class="protocol" @click.stop="showProtocolModal = true">《授权协议》</span></span>
        </div>
      </footer>
      <div class="mask"></div>
    </div>

    <!-- 第一页：用户 -->
    <div v-if="currentPage === 1" :class="['screen1', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="ml52 mt176 animate__animated animate__fadeInUp">
        <span title="Hi，" class="stroke_74ffdd fw5 fs32">Hi，</span>
        <span :title="report.nickname" class="stroke_74ffdd fw9 fs72">{{ report.nickname }}</span>
      </div>
      <div v-if="animatePlans.includes(1)" class="ml52 mt28 column">
        <div>
          <span class="stroke_74ffdd fs72 fw9 animate__animated animate__fadeInUp" 
            :title="report.registration_time[0]">{{ report.registration_time[0] }}</span>
          <span class="stroke_74ffdd fs32 animate__animated animate__fadeInUp" 
            title="年">年</span>
          <span class="stroke_74ffdd fs72 fw9 animate__animated animate__fadeInUp" 
            :title="report.registration_time[1]">{{ report.registration_time[1] }}</span>
          <span class="stroke_74ffdd fs32 animate__animated animate__fadeInUp" 
            title="月">月</span>
          <span class="stroke_74ffdd fs72 fw9 animate__animated animate__fadeInUp" 
            :title="report.registration_time[2]">{{ report.registration_time[2] }}</span>
          <span class="stroke_74ffdd fs32 animate__animated animate__fadeInUp" 
            title="日">日</span>
        </div>
        <span class="stroke_74ffdd fs32 fw5 mt28 animate__animated animate__fadeInUp" title="你第一次打开了粗门">你第一次打开了粗门</span>
      </div>
      <div class="column ml52 mt28">
        <div v-if="animatePlans.includes(2)" 
          class="fw5 fs32 h40 stroke_74ffdd animate__animated animate__fadeInUp" 
          title="在这里">在这里</div>
        <div v-if="animatePlans.includes(2)" class="h80 mt28">
          <span class="stroke_74ffdd fw5 fs32 animate__animated animate__fadeInUp" 
            title="你参加了">你参加了</span>
          <span class="stroke_74ffdd fw9 fs72 animate__animated animate__fadeInUp" 
            :title="report.participation_count">{{ report.participation_count }}</span>
          <span class="stroke_74ffdd fw5 fs32 animate__animated animate__fadeInUp" 
            title="次活动">次活动</span>
        </div>
        <div v-if="animatePlans.includes(2)" class="fs32 h80 mt28">
          <span class="stroke_74ffdd fw5 fs32 animate__animated animate__fadeInUp" 
            title="你的快乐时长达到">你的快乐时长达到</span>
          <span class="stroke_74ffdd fw9 fs72 animate__animated animate__fadeInUp" 
            :title="report.total_participation_time">{{ report.total_participation_time }}</span>
          <span class="stroke_74ffdd fw5 fs32 animate__animated animate__fadeInUp" 
            title="小时+">小时+</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(3) && report.participation_count >= 0 && report.total_participation_time < 5" 
        class="stroke_74ffdd fw5 mt28 h40 fs32 ml52 animate__animated animate__fadeInUp" 
        title="有更多快乐瞬间等你挖掘哦">有更多快乐瞬间等你挖掘哦</div>
      <div v-if="animatePlans.includes(3) && report.participation_count >= 5 && report.total_participation_time < 15" 
        class="stroke_74ffdd fw5 mt28 h40 fs32 ml52 animate__animated animate__fadeInUp" 
        title="能够找到喜欢的活动，是一件幸福的事情吧！">能够找到喜欢的活动，是一件幸福的事情吧！</div>
      <div v-if="animatePlans.includes(3) && report.participation_count >= 15" 
        class="stroke_74ffdd fw5 mt28 h40 fs32 ml52 animate__animated animate__fadeInUp" 
        title="你一定是E人吧？！">你一定是E人吧？！</div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" 
        @click="handleToNext"></div>
    </div>

    <!-- 第二页：用户 -->
    <div v-if="currentPage === 2" :class="['screen2', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="ml52 mt176 column">
        <div class="stroke_07ffc5 h40 fs32 fw5 animate__animated animate__fadeInUp" title="这一年">这一年</div>
        <div class="h80 mt28">
          <span class="stroke_07ffc5 fs32 fw5 animate__animated animate__fadeInUp" 
            title="你一共参加了">你一共参加了</span>
          <span class="stroke_07ffc5 fs72 fw9 animate__animated animate__fadeInUp" 
            :title="report.num_of_post_tags_participated">{{ report.num_of_post_tags_participated }}</span>
          <span class="stroke_07ffc5 fs32 fw5 animate__animated animate__fadeInUp" 
            title="次活动">种活动</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(1)" class="ml52 mt28 column">
        <div class="stroke_07ffc5 fs32 fw5 animate__animated animate__fadeInUp" title="其中">其中</div>
        <div class="stroke_07ffc5 fs72 fw9 mt28 animate__animated animate__fadeInUp" 
          :title="report.top_3_favorite_post_tags_string">{{ report.top_3_favorite_post_tags_string }}</div>
        <div class="stroke_07ffc5 fs32 fw5 mt28 animate__animated animate__fadeInUp" 
          title="是你的最爱">是你的最爱</div>
      </div>
      <div v-if="animatePlans.includes(2) && report.top_3_favorite_post_tags.length === 1" 
        class="ml52 mt28 fs32 fw5 stroke_07ffc5 animate__animated animate__fadeInUp" 
        title="事实说明，你真的很专一">事实说明，你真的很专一</div>
      <div v-if="animatePlans.includes(2) && report.top_3_favorite_post_tags.length > 1" 
        class="ml52 mt28 fs32 fw5 stroke_07ffc5 animate__animated animate__fadeInUp" 
        title="有趣活动收割机，非你莫属！">有趣活动收割机，非你莫属！</div>
      <div v-if="animatePlans.includes(3)" class="photo-box animate__animated animate__bounceIn">
        <div></div>
        <img alt=""
          :src="require('./assets/images/tag-cover_' + report.top_3_favorite_post_tags_cover_id + '.png')" 
        >
        <div></div>
      </div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第三页：用户 -->
    <div v-if="currentPage === 3" :class="['screen3', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="ml52 mt176 column">
        <div class="h40 fs32 fw5 stroke_74ffdd animate__animated animate__fadeInUp" title="这一年">这一年</div>
        <div class="mt28 h80 fw5">
          <span class="fs32 stroke_74ffdd fw5 animate__animated animate__fadeInUp" 
            title="你一共参加了">你一共参加了</span>
          <span class="fs32 stroke_74ffdd fs72 fw9 animate__animated animate__fadeInUp" 
            :title="report.num_of_clubs_participated">{{ report.num_of_clubs_participated }}</span>
          <span class="fs32 stroke_74ffdd fw5 animate__animated animate__fadeInUp" 
            title="个俱乐部举办的活动">个俱乐部举办的活动</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(1)" class="column ml52 mt28">
        <div class="stroke_74ffdd fs32 fw5 animate__animated animate__fadeInUp" title="其中">其中</div>
        <div class="club-box mt28 animate__animated animate__fadeInUp">
          <img class="avatar" :src="report.most_participated_clubs[0].logo_url" alt="">
          <span class="stroke_74ffdd fs32 fw9" 
            :title="report.most_participated_clubs[0].club_name">{{ report.most_participated_clubs[0].club_name }}</span>
        </div>
        <div class="fs32 stroke_74ffdd fw5 mt28 animate__animated animate__fadeInUp" 
          title="是你的快乐老家">是你的快乐老家</div>
      </div>
      <div v-if="animatePlans.includes(2)" 
        class="h40 fs32 stroke_74ffdd fw5 ml52 mt28 animate__animated animate__fadeInUp" 
        title="你是否在这里结交到投缘的小伙伴啦？">你是否在这里结交到投缘的小伙伴啦？</div>
      <img v-if="animatePlans.includes(1)" class="avatar_home animate__animated animate__fadeIn" :src="report.most_participated_clubs[0].logo_url" alt="">
      <div v-if="animatePlans.includes(2)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第四页：用户 -->
    <div v-if="currentPage === 4" :class="['screen4', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="ml52 mt176 column">
        <div class="fs32 stroke_003a48 fw5 animate__animated animate__fadeInUp" 
          title="在粗门的日子">在粗门的日子</div>
        <div class="mt28 h80">
          <span class="stroke_003a48 fs32 fw5 animate__animated animate__fadeInUp" title="有">有</span>
          <span class="fs72 stroke_003a48 fw9 animate__animated animate__fadeInUp" 
            :title="report.played_together_user_count">{{ report.played_together_user_count }}</span>
          <span class="stroke_003a48 fs32 fw5 animate__animated animate__fadeInUp" 
            title="位小伙伴和你一起玩耍">位小伙伴和你一起玩耍</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(1)" class="avatars">
        <div v-for="user in report.top_3_partner_users" :key="user.nickname" class="avatar-item animate__animated animate__bounceIn">
          <img class="avatar" :src="user.avatar_url" alt="">
          <div class="nickname">{{ user.nickname }}</div>
        </div>
      </div>
      <div v-if="animatePlans.includes(2)" class="column ml52 animate__animated animate__fadeInUp">
        <div class="stroke_003a48 fs32 fw5" title="是和你最同频的搭子">是和你最同频的搭子</div>
        <div class="stroke_003a48 fs32 fw5 mt28" title="同频的人，总能相遇">同频的人，总能相遇</div>
      </div>
      <div v-if="animatePlans.includes(3)" class="photo2 animate__animated animate__flipInX"></div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第五页：主理人 -->
    <div v-if="currentPage === 5" :class="['screen5', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="ml52 mt176 column">
        <div class="h80">
          <span class="stroke_07ffc5 fs72 fw9 animate__animated animate__fadeInUp" 
            :title="clubReport.club_registration_time[0]">{{ clubReport.club_registration_time[0] }}</span>
          <span class="stroke_07ffc5 fs32 animate__animated animate__fadeInUp" title="年">年</span>
          <span class="stroke_07ffc5 fs72 fw9 animate__animated animate__fadeInUp" 
            :title="clubReport.club_registration_time[1]">{{ clubReport.club_registration_time[1] }}</span>
          <span class="stroke_07ffc5 fs32 animate__animated animate__fadeInUp" title="月">月</span>
          <span class="stroke_07ffc5 fs72 fw9 animate__animated animate__fadeInUp" 
            :title="clubReport.club_registration_time[2]">{{ clubReport.club_registration_time[2] }}</span>
          <span class="stroke_07ffc5 fs32 animate__animated animate__fadeInUp" title="日">日</span>
        </div>
        <div class="stroke_07ffc5 fs32 mt28 animate__animated animate__fadeInUp" 
          title="你在粗门拥有了自己的俱乐部">你在粗门拥有了自己的俱乐部</div>
        <div class="club-box mt28 animate__animated animate__fadeInUp">
          <img class="avatar" :src="clubReport.club_avatar_url" alt="">
          <span class="stroke_07ffc5 fs64 fw9" :title="clubReport.club_name">{{ clubReport.club_name}}</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(1)" class="ml52 stroke_07ffc5 fs32 mt28 animate__animated animate__fadeInUp" 
        title="主理人成了你的新身份">主理人成了你的新身份</div>
      <div v-if="animatePlans.includes(2)" class="column h80 ml52 mt28" style="z-index: 2;">
        <div class="animate__animated animate__fadeInUp">
          <span class="stroke_07ffc5 fs72 fw9" :title="clubReport.first_publish_time[1]">{{ clubReport.first_publish_time[1] }}</span>
          <span class="stroke_07ffc5 fs32 fw5" title="月">月</span>
          <span class="stroke_07ffc5 fs72 fw9" :title="clubReport.first_publish_time[2]">{{ clubReport.first_publish_time[2] }}</span>
          <span class="stroke_07ffc5 fs32 fw5" title="日">日</span>
          <span class="stroke_07ffc5 fs72 fw9" style="margin-left: 4px" :title="clubReport.first_publish_time[3] + ':' + clubReport.first_publish_time[4]">{{ clubReport.first_publish_time[3] + ':' + clubReport.first_publish_time[4] }}</span>
        </div>
        <div class="stroke_07ffc5 fs32 mt28 animate__animated animate__fadeInUp" 
          title="你发布了第一个活动">你发布了第一个活动</div>
        <div class="stroke_07ffc5 fs32 mt28 animate__animated animate__fadeInUp" 
          title="初次等待小伙伴上车的心情，还记得吗？">初次等待小伙伴上车的心情，还记得吗？</div>
      </div>
      <div v-if="animatePlans.includes(3)" class="fireworks animate__animated animate__bounceIn"></div>
      <div class="house">
        <img v-if="animatePlans.includes(3)" :src="clubReport.club_avatar_url" alt="" class="logo animate__animated animate__fadeIn">
      </div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第六页：主理人 -->
    <div v-if="currentPage === 6" :class="['screen6', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="stroke_fff fs32 fw5 ml52 mt176 animate__animated animate__fadeInUp" 
        title="这一年">这一年</div>
      <div v-if="animatePlans.includes(0)" class="mt28 ml52 animate__animated animate__fadeInUp">
        <span class="stroke_fff fs32 fw5" title="你为">你为</span>
        <span class="stroke_fff fs72 fw9" :title="clubReport.published_post_count">{{ clubReport.published_post_count }}</span>
        <span class="stroke_fff fs32 fw5" title="场活动奔波忙碌着,其中">场活动奔波忙碌着,其中</span>
      </div>
      <div v-if="animatePlans.includes(0)" class="stroke_fff fs64 fw9 nowrap mt28 ml52 animate__animated animate__fadeInUp" 
        :title="clubReport.top_3_published_tag_ids_string">{{ clubReport.top_3_published_tag_ids_string }}</div>
      <div v-if="animatePlans.includes(1)" class="ml52 stroke_fff fs32 fw5 mt28 animate__animated animate__fadeInUp" 
        title="是你的热爱">是你的热爱</div>
      <div v-if="animatePlans.includes(2)" class="photo-box-club animate__animated animate__fadeIn">
        <img class="cover" :src="clubReport.most_participated_post.cover_obj.cover" alt="">
        <div class="badge"></div>
        <div class="title">{{ clubReport.most_participated_post.title }}</div>
        <div class="info">
          <div class="icon-clock"></div>
          <span>{{ clubReport.most_participated_post.date_range }}</span>
        </div>
        <div class="info">
          <div class="icon-poi"></div>
          <span>{{ clubReport.most_participated_post.address_name }}</span>
        </div>
      </div>
      <div v-if="animatePlans.includes(3)" class="info-box-1 animate__animated animate__fadeInUp">
        <span class="stroke_fff fs32 fw5" title="这场活动聚集了">这场活动聚集了</span>
        <span class="stroke_fff fs72 fw9" :title="clubReport.most_participated_post.participant_count">{{ clubReport.most_participated_post.participant_count}}</span>
        <span class="stroke_fff fs32 fw5" title="位参与者">位参与者</span>
      </div>
      <div v-if="animatePlans.includes(3)" 
        class="info-box-2 stroke_fff fs32 mt28 fw5 animate__animated animate__fadeInUp" 
        title="你俨然成为了人群中的快乐领袖">你俨然成为了人群中的快乐领袖</div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第七页：主理人 -->
    <div v-if="currentPage === 7" :class="['screen7', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" class="stroke_fbff4b fs32 fw5 ml52 mt176 animate__animated animate__fadeInUp" 
        title="这一年">这一年</div>
      <div v-if="animatePlans.includes(0)" class="mt28 ml52 h80 animate__animated animate__fadeInUp">
        <span class="fs32 fw5" title="你为">你为</span>
        <span class="fs72 fw9" :title="clubReport.participant_count">{{ clubReport.participant_count }}</span>
        <span class="fs32 fw5" title="位小伙伴带来了快乐">位小伙伴带来了快乐</span>
      </div>
      <div v-if="animatePlans.includes(1)" class="ml52 mt28 stroke_fbff4b fs32 fw5 animate__animated animate__fadeInUp" 
        title="他们真的很喜欢和你一起玩">他们真的很喜欢和你一起玩</div>
      <img v-if="animatePlans.includes(2)" class="club-logo animate__animated animate__bounceIn" :src="clubReport.club_avatar_url">
      <div v-if="animatePlans.includes(3)" class="member-avatars">
        <img v-for="user in clubReport.top_10_participated_users" :key="user.nickname" 
        class="member-avatar animate__animated animate__bounceIn" :src="user.avatar_url" />
      </div>
      <div v-if="animatePlans.includes(3)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第八页：主理人 -->
    <div v-if="currentPage === 8" :class="['screen8', 'column', 'animate__animated', 'animate__fadeIn']">
      <div v-if="animatePlans.includes(0)" title="悄悄告诉你" 
        class="stroke_07ffc5 fs32 fw5 ml52 mt176 animate__animated animate__fadeInUp">悄悄告诉你</div>
      <div v-if="animatePlans.includes(1)" class="user-box mt28 animate__animated animate__fadeInUp">
        <img class="avatar" :src="clubReport.top_10_participated_users[0].avatar_url" alt="">
        <span class="stroke_07ffc5 fs64 fw9" :title="clubReport.top_10_participated_users[0].nickname">{{ clubReport.top_10_participated_users[0].nickname }}</span>
      </div>
      <div v-if="animatePlans.includes(1)" title="是参加你活动最多的人" 
        class="ml52 mt28 fs32 fw5 stroke_07ffc5 animate__animated animate__fadeInUp">是参加你活动最多的人</div>
      <div v-if="animatePlans.includes(2)" title="TA真是你的“榜一大哥”！" 
        class="ml52 mt28 fs32 fw5 stroke_07ffc5 animate__animated animate__bounceIn">TA真是你的“榜一大哥”！</div>
      <div v-if="animatePlans.includes(2)" 
        class="next-page animate__animated animate__fadeIn" @click="handleToNext"></div>
    </div>

    <!-- 第九页 -->
    <div v-if="currentPage === 9" :class="['screen9', 'animate__animated', 'animate__fadeIn']">
      <img v-if="animatePlans.includes(0)" class="s9-content animate__animated animate__fadeIn" 
      :src="require('./assets/images/s9-content.png')" alt="">
      <img v-if="animatePlans.includes(1)" class="s9-team animate__animated animate__fadeIn" 
      :src="require('./assets/images/s9-team.png')" alt="">
      <img v-if="animatePlans.includes(2)" class="s9-cucu animate__animated animate__bounceIn" 
      :src="require('./assets/images/s9-cucu.png')" alt="">
      <img v-if="animatePlans.includes(3)" 
        class="btn-generate-poster animate__animated animate__tada" 
        :src="require('./assets/images/btn-generate-poster.png')"
        @click="handleToNext" />
    </div>

    <!-- 第十页：主理人 -->
    <div v-if="currentPage === 10" :class="['screen10', 'animate__animated', 'animate__fadeIn']">
      <div v-if="currentShowUser" class="user">
        <div class="user-info">
          <img class="avatar" :src="report.avatar_url" alt="">
          <span class="nickname">@{{ report.nickname }}</span>
        </div>
        <img class="cover" :src="require('./assets/images/title-cover_' + report.randomIndex + '.png')" alt="" />
        <img class="title" :src="require('./assets/images/title-text_' + report.randomIndex + '.png')" alt=""/>
        <div class="category">{{ report.top_3_favorite_post_tags_string }}</div>
        <div class="join">参与活动{{ report.participation_count }}次</div>
      </div>
      <div v-else class="club">
        <header>
          <img class="avatar" :src="clubReport.club_avatar_url" alt="">
          <div class="nickname">{{ clubReport.club_name }}</div>
        </header>
        <img class="title" :src="require('./assets/images/club-title_' + (clubReport.randomIndex || 0) + '.png')" alt="">
        <div class="medal"></div>
        <div class="release-count-box">发布活动: <span>{{ clubReport.published_post_count }}场</span></div>
        <div class="release-kind-box">发布品类: <span>{{ clubReport.top_3_published_tag_ids_string }}</span></div>
        <div class="join-count-box">参与人数: <span>{{ clubReport.participant_count }}人</span></div>
        <div class="content">2024期待与你的更多故事</div>
      </div>
      <div v-show="!screenshoting" class="btn-box">
        <!-- 非主理人：保存图片 -->
        <img v-if="!clubId || !report.hasOwnProperty('user_id')" :src="require('./assets/images/btn-save.png')" alt="" 
          @click="handleScreenshotAndSave">
        <!-- 主理人：查看用户称号 -->
        <img v-if="clubId && !currentShowUser && report.hasOwnProperty('user_id')" 
          :src="require('./assets/images/btn-user-title.png')" alt="" @click="handleSwitchTitlePage">
        <!-- 主理人：查看俱乐部称号 -->
        <img v-if="clubId && currentShowUser" 
          :src="require('./assets/images/btn-club-title.png')" alt="" @click="handleSwitchTitlePage">
        <!-- 所有人：分享 -->
        <img :src="require('./assets/images/btn-share.png')" alt="" 
          @click="showShareModal = true">
      </div>
      <div v-if="showShareModal" class="share-modal">
        <div class="mask" @click="showShareModal = false"></div>
        <div class="modal">
          <div class="icon-box">
            <div v-if="clubId && report.hasOwnProperty('user_id')" class="item icon-save" @click="handleScreenshotAndSave"></div>
            <div class="item icon-session" @click="handleShareToWechat('session')"></div>
            <div class="item icon-timeline" @click="handleShareToWechat('timeline')"></div>
          </div>
          <div class="cancel" @click="showShareModal = false"></div>
        </div>
      </div>
    </div>

    <!-- toast -->
    <div v-show="!!toast.content" class="toast">{{ toast.content }}</div>

    <!-- 协议弹窗 -->
    <div v-show="showProtocolModal" class="protocol-modal">
      <div class="mask" @click="showProtocolModal = false"></div>
      <div class="content">
        <iframe src="https://assets.cumen.fun/static/agreement/year-report-2023-privacy.html" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import headerPage from "./components/header.vue";
import $fetch from "@/utils/fetch.js";
import { getDate, formatFeedsDate, hashString } from "@/utils/utils";
import VConsole from "vconsole";
export default {
  components: { headerPage },
  data() {
    return {
      auth: "",
      clubId: "",
      report: {
        registration_time: []
      },
      clubReport: {
        club_registration_time: []
      },
      animatePlans: [],
      timers: [],
      agreeProtocol: false,
      showProtocolModal: false,
      showShareModal: false,
      toast: { content: "", time: 3000 },
      hashNumber: 0,
      pageSize: {},
      pages: [0, 4, 4, 4, 4, 4, 4, 4, 3, 4, 5],
      currentPage: 0,
      startY: 0,
      currentY: 0,
      currentShowUser: true,
      screenshoting: false,
    }
  },
  async mounted() {
    /*this.auth = "sid-1dc6361d3de72cc739032a12792a9130";
    this.clubId = "0184af09-e123-3675-8bb5-43dc423cf18d";
    this.handleGetUserInfo();
    this.handleGetClubInfo();*/

    if(window.flutter_inappwebview.callHandler != null) {
      const session = await window.flutter_inappwebview.callHandler("getSession");
      let user = JSON.parse(await window.flutter_inappwebview.callHandler("getUserInfo"));
      this.auth= session;
      this.clubId = user.club.id;
      if(session) this.handleGetUserInfo();
      if(user.club.id) this.handleGetClubInfo();
    }
  },
  methods: {
    /** 获取用户信息 */
    handleGetUserInfo() {
      const { auth, clubId } = this;
      $fetch
        .post("GetUserReport", {
          viewType: 2,
        }, {
          Authorization: auth,
        }, process.env.VUE_APP_BASEPATH_YEAR_REPORT)
        .then((res) => {
          let report = res.user_report;
          if(report) {
            report.registration_time = getDate(report.registration_time);
            report.total_participation_time = ~~(Number(report.total_participation_time.replace('s', '')) / 60 / 60);
            report.top_3_favorite_post_tags = !report.top_3_favorite_post_tags.length ? [{ tag_id: 0 }] : report.top_3_favorite_post_tags.slice(0, 3);
            report.top_3_favorite_post_tags_string = report.top_3_favorite_post_tags.map(tag => tag.name).slice(0, 3).join(' ');
            report.top_3_favorite_post_tags_cover_id = [100, 202, 215, 221, 237, 245, 251, 260, 263, 567].includes(report.top_3_favorite_post_tags[0].tag_id) 
              ? report.top_3_favorite_post_tags[0].tag_id : 'other';
            report.most_participated_clubs = !report.most_participated_clubs.length ? [{ logo_url: "", club_name: "" }] : report.most_participated_clubs;
            report.randomIndex = hashString(report.user_id, 8);
            this.report = report;
            if(clubId) this.handleGetClubInfo();
          }
        });
    },
    /** 获取俱乐部信息 */
    handleGetClubInfo() {
      const { auth, clubId } = this;
      $fetch
        .post("GetClubReport", {
          viewType: 2,
        }, {
          Authorization: auth,
          "X-CUMEN-CLUB-ID": clubId
        }, process.env.VUE_APP_BASEPATH_YEAR_REPORT)
        .then((res) => {
          let report = res.club_report;
          if(report) {
            report.club_registration_time = getDate(report.club_registration_time);
            report.first_publish_time = getDate(report.first_publish_time);
            report.top_3_published_tag_ids_string = report.top_3_published_tag_ids.map(tag => tag.name).slice(0, 3).join(' ');
            report.most_participated_post.date_range = formatFeedsDate(
              +new Date(report.most_participated_post.start_time), 
              +new Date(report.most_participated_post.end_time)
            );
            if(report.most_participated_post.cover_obj.image_info) {
              report.most_participated_post.cover_obj.cover = report.most_participated_post.cover_obj.image_info.thumbnail_url; 
            } else {
              report.most_participated_post.cover_obj.cover = report.most_participated_post.cover_obj.video_info.thumbnail;
            }
            report.randomIndex = hashString(clubId, 6);
            report.top_10_participated_users[0].nickname = report.top_10_participated_users[0].nickname.substr(0, 8);
            this.clubReport = report;
          }
        });
    },
    /**
     * 插入动画计划
     * @param {Number} maxNum 当前屏需要播放动画的数量
     **/
    insertPlan(maxNum) {
      this.animatePlans = [];
      for(let i = 0; i < maxNum; i++) {
        this.timers.push(setTimeout(() => {
          this.animatePlans.push(i);
          i === maxNum - 1 && this.cleanTimers();
        }, 1000 * (i + 1)));
      }
    },
    /** 清理定时器 */
    cleanTimers() {
      for(let i = this.timers.length - 1; i >= 0; i--) {
        clearTimeout(this.timers[i]);
        this.timers.splice(i, 1);
      }
    },
    /** 获取滚动条位置 */
    getScrollTop() {
      return (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    },
    /** 回到顶部 */
    scrollToTop() {
      /** 获取当前滚动条位置 */
      var currentScroll = this.getScrollTop();
      /** 计算每一步的滚动距离，可以调整步长来改变滚动速度 */
      var step = Math.floor(currentScroll / 20);
      /** 使用requestAnimationFrame创建平滑滚动动画 */
      function scrollStep() {
        if (currentScroll > 0) {
          window.requestAnimationFrame(scrollStep);
          window.scrollTo(0, currentScroll -= step);
        }
      }
      /** 启动滚动动画 */
      scrollStep();
    },
    /** 显示提示 */
    handleShowToast(msg) {
      this.toast.content = msg;
      setTimeout(() => this.toast.content = '', this.toast.time);
    },
    /** 打开授权协议 */
    handleToProtocolPage() {
      window.open("https://assets.cumen.fun/static/agreement/year-report-2023-privacy.html", "_blank");
    },
    /** 跳到指定页 */
    handleSkipTo(pageIndex) {
      const { pages } = this;
      if(pages[pageIndex] !== undefined) {
        this.currentPage = pageIndex;
        this.insertPlan(pages[pageIndex]);
        this.scrollToTop();
      }
    },
    /** 下一页 */
    handleToNext() {
      let { pages, clubId, currentPage, agreeProtocol, report, clubReport } = this;
      const nextPage = currentPage + 1;


      /** 封面页需要检测是否同意协议 */
      if(nextPage === 1) {
        if(!agreeProtocol) return this.handleShowToast("请先同意授权协议");

        /** 既没有用户年报, 也没有俱乐部年报 */
        if(!report.hasOwnProperty('user_id') && !clubReport.hasOwnProperty("club_name")) return this.handleShowToast("年度报告不存在");

        this.$refs.header.handleSwitchAudioStatus();

        /** 只有用户年报 */
        if(report.hasOwnProperty("user_id") && !clubReport.hasOwnProperty("club_name")) this.clubId = "";

        /** 只有俱乐部年报 */
        if(clubReport.hasOwnProperty("club_name") && !report.hasOwnProperty("user_id")) {
          this.currentShowUser = false;
          return this.handleSkipTo(5)
        };
      } 

      /** 用户年报需要跳过俱乐部的年报页 */
      if(!clubId && nextPage === 5) return this.handleSkipTo(9);

      if(pages[nextPage] !== undefined) {
        this.currentPage = nextPage;
        this.insertPlan(pages[nextPage]);
        this.scrollToTop();
      }
    },
    /** 上一页 */
    handleToPrev() {
      let { pages, clubId, currentPage, report } = this;
      let prevPage = currentPage - 1;

      /** 纯用户年报需要跳过俱乐部的年报页 */
      if(!clubId && prevPage === 8) return this.handleSkipTo(4);

      /** 纯俱乐部年报需要禁止用户查看用户年报 */
      if(!report.hasOwnProperty("user_id") && clubId && prevPage === 4) return this.handleSkipTo(0);

      if(pages[prevPage] !== undefined) {
        this.currentPage = prevPage;
        this.insertPlan(pages[prevPage]);
        this.scrollToTop();
      }
    },
    /** 获取当前页面截图尺寸 */
    handleGetScreenSize() {
      const page = document.querySelector(".screen10"); 
      return page.getBoundingClientRect();
    },
    /** 分享到微信 */
    async handleShareToWechat(sharedScene) {
      const { clubId } = this;
      this.showShareModal = false;
      this.screenshoting = true;
      const { width } = this.handleGetScreenSize();
      await window.flutter_inappwebview.callHandler('takeScreenshotAndShareToWeChat', {
        takeScreenshot: {
          rect: { x: 0, y: 0, width, height: (width * (!!clubId ? 1.866 : 1.8906)).toFixed(2) }, 
          quality: 60, 
          snapshotWidth: width * 2
        },
        sharedScene
      });
      this.screenshoting = false;
    },
    /**  截图保存 */
    async handleScreenshotAndSave() {
      const { clubId } = this;
      this.showShareModal = false;
      this.screenshoting = true;
      const { width } = this.handleGetScreenSize();
      await window.flutter_inappwebview.callHandler('takeScreenshotAndSaveToAlbum', {
        takeScreenshot: {
          rect: { x: 0, y: 0, width, height: (width * (!!clubId ? 1.866 : 1.8906)).toFixed(2) }, 
          quality: 60, 
          snapshotWidth: width * 2
        },
      });
      this.screenshoting = false;
    },
    /** 触屏开始 */
    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    /** 触屏移动 */
    handleTouchMove(event) {
      event.preventDefault();
      this.currentY = event.touches[0].clientY;
    },
    /** 触屏结束 */
    handleTouchEnd() {
      const { pages, animatePlans, currentPage } = this;
      if (this.startY !== null && this.currentY !== null) {
        const deltaY = this.currentY - this.startY;

        if(pages[currentPage] === animatePlans.length) {
          if (deltaY > 50) this.handleToPrev();
          else if (deltaY < -50 && !!this.currentY) this.handleToNext();
        }

        // 清空起始和当前位置
        this.startY = null;
        this.currentY = null;
      }
    },
    /** 主理人查看用户称号 */
    handleSwitchTitlePage() {
      const { currentShowUser } = this;
      this.currentShowUser = !currentShowUser;
      this.handleSkipTo(10);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

$screenHeight: 216.533vw;
$color-green: #1ffd91;

* {
  -webkit-tap-highlight-color: transparent;
}

.container {
  width: 100%;
  height: 100vh;
  min-height: $screenHeight;
  background-color: #222;
}

.stroke_74ffdd {
  @include stroke_color(#74ffdd);
}

.stroke_07ffc5 {
  @include stroke_color(#07ffc5);
}

.stroke_003a48 {
  @include stroke_color(#003a48, $color-green);
}

.stroke_fff {
  @include stroke_color(#fff);
}

.stroke_fbff4b {
  @include stroke_color(#fbff4b);
}

.stroke_222 {
  @include stroke_color(#222, #fff);
}

.fw4 {
  font-weight: 400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}

.fw7 {
  font-weight: 700;
}

.fw8 {
  font-weight: 800;
}

.fw9 {
  font-weight: 900;
}

.fs32 {
  font-size: px2rem(32);
  line-height: px2rem(40);
}

.fs64 {
  font-size: px2rem(64);
  line-height: px2rem(64);
}

.fs72 {
  font-size: px2rem(72);
  line-height: px2rem(80);
}

.ml52 {
  margin-left: px2rem(52);
}

.h40 {
  height: px2rem(40);
  line-height: px2rem(40);
}

.h80 {
  height: px2rem(80);
  line-height: px2rem(80);
}

.mt28 {
  margin-top: px2rem(28);
}

.mt176 {
  margin-top: px2rem(176);
}

.nowrap {
  white-space: nowrap;
}

.row {
  @include row;
}

.column {
  @include column;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

[class^=screen] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  min-height: $screenHeight;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  z-index: 1;
}

.next-page {
  position: absolute;
  left: 50%;
  bottom: px2rem(86);
  transform: translateX(-50%);
  width: px2rem(188);
  height: px2rem(92);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

/** 封面页 */
.screen-cover {
  background-image: url("./assets/images/screen-cover.png");

  > footer {
    @include column(center, center);
    position: fixed;
    left: 0;
    bottom: px2rem(64);
    width: 100%;
    z-index: 1;

    .btn-open-annual-report {
      width: px2rem(432);
      height: px2rem(80);
      background: url(./assets/images/btn-open-annual-report.png) no-repeat center / cover;
    }

    .protocol-box {
      @include row(center, center);
      height: px2rem(64);
      margin-top: px2rem(8);

      .radio {
        display: inline-block;
        width: px2rem(32);
        height: px2rem(32);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .radio_not {
        background-image: url(./assets/images/radio_not.png);
      }

      .radio_sel {
        background-image: url(./assets/images/radio_sel.png); 
      }

      .protocol-content {
        color: #fff;
        font-size: px2rem(24);
        margin-left: px2rem(8);
      }

      .protocol {
        font-size: px2rem(24);
        font-weight: bold;
      }
    }
  }

  .mask {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: px2rem(204);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .7));
  }
}


/** 第一页 */
.screen1 {
  background-image: url("./assets/images/screen1.png");

  > div {
    &:first-child {
      height: px2rem(80);
      line-height: px2rem(80);
    }
  }

  > .next-page {
    background-image: url("./assets/images/next-page_white.png");
  }
}

/** 第二页 */
.screen2 {
  background-image: url("./assets/images/screen2.png");

  .photo-box {
    position: absolute;
    bottom: px2rem(218);
    left: px2rem(16);
    width: px2rem(718);
    height: px2rem(736);
    background: url("./assets/images/photo-box.png") no-repeat center / cover;

    > div {
      &:first-child {
        position: absolute;
        top: px2rem(60);
        left: px2rem(148);
        width: px2rem(100);
        height: px2rem(100);
        background: url("./assets/images/icon-thumbtack.png") no-repeat center / cover;
        z-index: 1;
      }

      &:last-child {
        position: absolute;
        right: px2rem(24);
        bottom: px2rem(46);
        width: px2rem(226);
        height: px2rem(258);
        background: url("./assets/images/icon-arrow.png") no-repeat center / cover;
        z-index: 1;
      }
    }

    > img {
      position: absolute;
      left: px2rem(114);
      top: px2rem(86);
      width: px2rem(572);
      height: px2rem(594);
      background-color: #fff;
    }
  }

  > .next-page {
    background-image: url("./assets/images/next-page_black.png");
  }
}

/** 第三页 */
.screen3 {
  background-image: url("./assets/images/screen3.png");

  > div {

    .club-box {
      @include row(flex-start, center); 

      > .avatar {
        width: px2rem(124);
        height: px2rem(124);
        margin-right: px2rem(24);
        border: px2rem(4) solid $color-green;
        background-color: $color-green;
        border-radius: 50%;
      }
    }
  }

  .avatar_home {
    position: absolute;
    left: px2rem(125);
    top: px2rem(1050);
    width: px2rem(152);
    height: px2rem(152);
    background-color: $color-green;
    border-radius: 50%;
  }

  > .next-page {
    background-image: url("./assets/images/next-page_all-black.png");
  }
}

/** 第四页 */
.screen4 {
  background-image: url("./assets/images/screen4.png");

  > .avatars {
    @include row(center, center);
    width: 100%;
    height: px2rem(160);
    margin-top: px2rem(98);
    margin-bottom: px2rem(50);

    > .avatar-item {
      @include column(space-between, center);
      width: px2rem(112);
      height: px2rem(160);

      &:not(:first-child) {
        margin-left: px2rem(56);
      }

      > .avatar {
        width: px2rem(112);
        height: px2rem(112);
        border-radius: 50%;
        background-color: orange;
        border: px2rem(4) solid #003a48;
        box-sizing: border-box;
      }

      > .nickname {
        font-size: px2rem(24);
        color: #fff;
      }
    }
  }

  > .photo2 {
    position: absolute;
    top: px2rem(842);
    left: px2rem(24);
    width: px2rem(702);
    height: px2rem(618);
    background: url("./assets/images/photo2.png") no-repeat center / cover;
  }

  > .next-page {
    background-image: url("./assets/images/next-page_all-white.png");
  }
}

/** 第五页 */
.screen5 {
  background-image: url("./assets/images/screen5.png");

  .club-box {
    @include row(flex-start, center); 

    > .avatar {
      width: px2rem(96);
      height: px2rem(96);
      margin-right: px2rem(24);
      border: px2rem(4) solid $color-green;
      background-color: #fff;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  > .fireworks {
    position: absolute;
  }

  > .fireworks {
    position: absolute;
    left: px2rem(-100);
    bottom: px2rem(304);
    width: px2rem(712);
    height: px2rem(654);
    transform: rotate(12deg);
    background: url("./assets/images/fireworks.png") no-repeat center / cover;
  }

  > .house {
    position: absolute;
    left: px2rem(-66);
    bottom: px2rem(180);
    width: px2rem(850);
    height: px2rem(714);
    background: url("./assets/images/house-club.png") no-repeat center / cover;

    > .logo {
      position: absolute;
      left: px2rem(208);
      top: px2rem(330);
      width: px2rem(130);
      height: px2rem(130);
      background-color: #f5f5f5;
      border-radius: 50%;
    }
  }

  > .next-page {
    background-image: url("./assets/images/next-page_white.png");
  }
}

/** 第六页 */
.screen6 {
  background-image: url("./assets/images/screen6.png");

  > .photo-box-club {
    position: absolute;
    left: px2rem(-2);
    top: px2rem(420);
    width: calc(px2rem(750) + px2rem(10));
    height: px2rem(898);
    background: url("./assets/images/photo3.png") no-repeat center / cover;

    > .cover {
      position: absolute;
      left: px2rem(152);
      top: px2rem(118);
      width: px2rem(436);
      height: px2rem(436);
      transform: rotate(-5deg);
      background-color: #d8d8d8;
      object-fit: cover;
    }

    > .badge {
      position: absolute;
      left: px2rem(420);
      bottom: px2rem(324);
      width: px2rem(204);
      height: px2rem(204);
      transform: rotate(-5deg);
      background: url("./assets/images/medal-completion.png") no-repeat center / cover;
    }

    > .title {
      @include multi-ellipsis(2);
      position: absolute;
      left: px2rem(182);
      bottom: px2rem(220);
      transform: rotate(-5deg);
      width: px2rem(416);
      height: px2rem(72);
      color: #111;
      font-weight: 500;
      font-size: px2rem(24);
    }

    > .info {
      @include row;
      position: absolute;
      left: px2rem(182);
      bottom: px2rem(176);
      transform: rotate(-5deg);
      width: px2rem(400);
      height: px2rem(32);

      > .icon-clock {
        width: px2rem(32);
        height: px2rem(32);
        background: url("./assets/images/icon-clock.png") no-repeat center / cover;
      }

      > .icon-poi {
        width: px2rem(32);
        height: px2rem(32);
        background: url("./assets/images/icon-poi.png") no-repeat center / cover;
      }

      > span {
        color: #a3a3a3;
        font-size: px2rem(22);
      }

      &:last-child {
        left: px2rem(186);
        bottom: px2rem(138);
      }
    }
  }

  > .info-box-1 {
    position: absolute;
    right: px2rem(48);
    top: px2rem(1266);
  }

  > .info-box-2 {
    position: absolute;
    right: px2rem(48);
    top: px2rem(1374);
  }

  > .next-page {
    background-image: url("./assets/images/next-page_white.png");
  }
}

/** 第七页 */
.screen7 {
  background-image: url("./assets/images/screen7.png");

  > .club-logo, .member-avatar {
    position: absolute;
    border-radius: 50%;
  }

  > .club-logo {
    left: px2rem(236);
    top: px2rem(932);
    width: px2rem(160);
    height: px2rem(160);
    background: #f5f5f5;
  }

  > .member-avatars {
    position: absolute;
    top: px2rem(720);
    width: 100%;
    height: px2rem(900);

    > .member-avatar {
      background-color: skyblue;

      &:first-child {
        left: px2rem(112);
        bottom: px2rem(364);
        width: px2rem(96);
        height: px2rem(96);
      }

      &:nth-of-type(2) {
        right: px2rem(262);
        bottom: px2rem(698);
        width: px2rem(108);
        height: px2rem(108);
      }

      &:nth-of-type(3) {
        right: px2rem(110);
        bottom: px2rem(602);
        width: px2rem(96);
        height: px2rem(96);
      }

      &:nth-of-type(4) {
        left: px2rem(102);
        bottom: px2rem(590);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(5) {
        right: px2rem(222);
        bottom: px2rem(364);
        width: px2rem(96);
        height: px2rem(96);
      }

      &:nth-of-type(6) {
        right: px2rem(110);
        bottom: px2rem(270);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(6) {
        right: px2rem(304);
        bottom: px2rem(150);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(7) {
        right: px2rem(110);
        bottom: px2rem(108);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(8) {
        left: px2rem(36);
        bottom: px2rem(68);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(9) {
        right: px2rem(110);
        bottom: px2rem(270);
        width: px2rem(72);
        height: px2rem(72);
      }

      &:nth-of-type(10) {
        left: px2rem(148);
        bottom: px2rem(204);
        width: px2rem(72);
        height: px2rem(72);
      }
    }

  }

  > .next-page {
    background-image: url("./assets/images/next-page_white.png");
  }
}

/** 第八页 */
.screen8 {
  background-image: url("./assets/images/screen8.png");

  > .user-box {
    @include row(flex-start, center); 

    > .avatar {
      width: px2rem(160);
      height: px2rem(160);
      margin-left: px2rem(52);
      margin-right: px2rem(16);
      background-color: #fff;
      border-radius: 50%;
      box-sizing: border-box;
    }

    > span {
      white-space: nowrap;
    }
  }

  > .next-page {
    background-image: url("./assets/images/next-page_all-black.png");
  }
}

/** 第九页 */
.screen9 {
  background-image: url("./assets/images/screen9.png");

  > .s9-content {
    position: absolute;
    top: px2rem(326);
    left: px2rem(126);
    width: px2rem(560);
    height: px2rem(442);
  }

  > .s9-team {
    position: absolute;
    top: px2rem(758);
    right: px2rem(140);
    width: px2rem(168);
    height: px2rem(88);
  }

  > .s9-cucu {
    position: absolute;
    top: px2rem(844);
    right: px2rem(128);
    width: px2rem(268);
    height: px2rem(192);
  }

  .btn-generate-poster {
    position: fixed;
    left: calc(50% - px2rem(216));
    bottom: px2rem(80);
    width: px2rem(432);
    height: px2rem(88);
  }
}

/** 第十页 */
.screen10 {
  position: absolute;

  > .user {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/images/screen10-user.png") no-repeat center top / contain;

    > .user-info {
      @include row(flex-start, center);
      margin-top: px2rem(228);
      margin-left: px2rem(100);
      width: px2rem(550);
      height: px2rem(64);
      
      > .avatar {
        flex-shrink: 0;
        width: px2rem(64);
        height: px2rem(64);
        border-radius: 50%;
        border: px2rem(4) solid #222;
        background-color: orange;
        box-sizing: border-box;
      }

      > .nickname {
        @include ellipsis;
        display: inline-block;
        margin-left: px2rem(6);
        font-size: px2rem(48);
        font-weight: bold;
        color: #3d3d3d
      }
    }

    > .cover {
      position: absolute;
      top: px2rem(338);
      left: px2rem(106);
      width: px2rem(540);
      height: px2rem(456);
      background-color: gray;
    }

    > .title {
      position: absolute;
      top: px2rem(852);
      left: px2rem(163);
      width: px2rem(416);
      height: px2rem(160);
    }

    > .category {
      @include ellipsis;
      position: absolute;
      top: px2rem(1116);
      left: px2rem(48);
      width: px2rem(320);
      height: px2rem(38);
      line-height: px2rem(38);
      color: #222;
      font-weight: 900;
      font-size: px2rem(32);
    }

    > .join {
      @include ellipsis;
      position: absolute;
      top: px2rem(1212);
      left: px2rem(48);
      width: px2rem(320);
      height: px2rem(38);
      line-height: px2rem(38);
      color: #222;
      font-weight: 900;
      font-size: px2rem(32);
    }
  }

  > .club {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/images/screen10.png") no-repeat center top / contain;

    > header {
      @include row(flex-start, center);
      position: absolute;
      left: px2rem(72);
      top: px2rem(138);
      width: calc(100% - px2rem(144));
      height: px2rem(72);

      .avatar {
        flex-shrink: 0;
        width: px2rem(72);
        height: 100%;
        border-radius: 50%;
        background-color: #f5f5f5;
      }

      .nickname {
        @include ellipsis;
        font-size: px2rem(48);
        height: px2rem(52);
        line-height: px2rem(52);
        margin-left: px2rem(8);
        font-weight: bold;
      }
    }

    > .title {
      position: absolute;
      left: calc(50% - px2rem(418) / 2);
      top: px2rem(342);
      width: px2rem(418);
      height: px2rem(140);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    > .medal {
      position: absolute;
      top: px2rem(440);
      right: px2rem(42);
      width: px2rem(220);
      height: px2rem(220);
      background: url("./assets/images/medal-club.png") no-repeat center center / cover;
      z-index: 1;
    }

    > .release-count-box,
      .release-kind-box,
      .join-count-box,
      .content {
      @include ellipsis;
      position: absolute;
      width: calc(100% - px2rem(220));
      height: px2rem(40);
      font-size: px2rem(32);
      line-height: px2rem(40);
      font-weight: 900;

      > span {
        color: #00ca94;
        font-weight: 900;
      }
    }

    > .release-count-box {
      left: px2rem(110);
      top: px2rem(580);
    }

    > .release-kind-box {
      left: px2rem(110);
      top: px2rem(658);
    }

    > .join-count-box {
      left: px2rem(110);
      top: px2rem(742);
    }

    > .content {
      left: px2rem(110);
      top: px2rem(824);
    }
  }


  > .btn-box {
    @include row(space-between, center);
    position: fixed;
    left: px2rem(24);
    bottom: px2rem(92);
    width: calc(100% - px2rem(48));
    height: px2rem(88);

    > img {
      width: px2rem(334);
      height: px2rem(88);
    }
  }

  > .share-modal {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;

    .mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      z-index: -1;
    }

    > .modal {
      position: absolute;
      left: 0;
      bottom: 0;
      width: px2rem(750);
      height: px2rem(538);
      background: url("./assets/images/share-modal.png") no-repeat center center / cover;

      > .icon-box {
        @include row;
        position: absolute;
        top: px2rem(186);
        height: px2rem(132);
        width: 100%;

        > .item {
          flex: 1;
          height: px2rem(132);
        }

        > .icon-save {
          background: url("./assets/images/share-save.png") no-repeat center center / contain;
        }

        > .icon-session {
          background: url("./assets/images/share-wechat.png") no-repeat center center / contain;
        }

        > .icon-timeline {
          background: url("./assets/images/share-timeline.png") no-repeat center center / contain;
        }
      }
    }
  }
}

.toast {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: px2rem(11) px2rem(24);
  text-align: center;
  font-size: px2rem(24);
  line-height: px2rem(46);
  color: #fff;
  background-color: rgba(0, 0, 0, .7);
  border-radius: px2rem(12);
  z-index: 9;
}

.protocol-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;

  > .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
  }

  > .content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60vh;
    background-color: #fff;
    border-top-left-radius: px2rem(48);
    border-top-right-radius: px2rem(48);

    > iframe {
      width: 100%;
      height: 100%;
      padding: px2rem(24);
      box-sizing: border-box;
    }
  }
}

</style>
