<template>
  <div class="container">
    <audio ref="audio" :src="music" preload loop></audio>
    <header>
      <div class="icon-back" @click="handleBack"></div>
      <div v-if="audioPlaying" class="icon-audio_playing" @click="handleSwitchAudioStatus"></div>
      <div v-else class="icon-audio_muted" @click="handleSwitchAudioStatus"></div>
    </header>
  </div>

</template>

<script>
import music from "../assets/medias/music.mp3";
export default {
  data() {
    return {
      music,
      audioPlaying: false,
    }
  },
  methods: {
    /** 控制音乐播放状态 */
    handleSwitchAudioStatus() {
      this.audioPlaying = !this.audioPlaying;
      this.audioPlaying ? this.$refs['audio'].play() : this.$refs['audio'].pause();
    },
    /** 返回 */
    handleBack() {
      window.flutter_inappwebview.callHandler('exit', {});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

header {
  @include row(space-between, center);
  position: fixed;
  left: 0;
  top: px2rem(88);
  width: 100%;
  height: px2rem(88);
  padding: 0 px2rem(32);
  box-sizing: border-box;
  z-index: 999;

  .icon-back, 
  .icon-audio_muted, 
  .icon-audio_playing {
    display: inline-block;
    width: px2rem(64);
    height: px2rem(64);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }

  .icon-back {
    background-image: url(../assets/images/icon-back.png);
  }

  .icon-audio_playing {
    transform-origin: center;
    animation: rotate 3s linear infinite;
    background-image: url(../assets/images/icon-audio_playing.png);
  }

  .icon-audio_muted {
    background-image: url(../assets/images/icon-audio_muted.png);
  }
}
</style>
